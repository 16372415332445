import axios from 'axios';

import {
  LoginParams,
  CreateUserParams,
  SettingParams,
  SettingResponse,
  SignalSettingParams,
  SignalSettingResponse,
  NasSettingParams,
  NasSettingResponse,
  BasSettingParams,
  BasSettingResponse,
} from './types';

const BASEURL = process.env.NEXT_PUBLIC_BACKEND_URL;

//변경

const instance = axios.create({
  baseURL: BASEURL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthHeader = (token: string) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Basic ${token}`;
  }
};

export const unsetAuthHeader = () => {
  delete instance.defaults.headers.common['Authorization'];
};

const api = {
  queries: {
    // User Setting
    getSetting: async (): Promise<SettingResponse> => {
      const { data } = await instance.get('/api/user/setting');
      return data;
    },
    getSignalSetting: async (): Promise<SignalSettingResponse> => {
      const { data } = await instance.get('/api/setting/signal');
      return data;
    },
    getNasSetting: async (): Promise<NasSettingResponse> => {
      const { data } = await instance.get('/api/setting/nas');
      return data;
    },
    getBasSetting: async (): Promise<BasSettingResponse> => {
      const { data } = await instance.get('/api/setting/bas');
      return data;
    },
  },
  mutations: {
    login: async ({ username, password }: LoginParams) => {
      const { data } = await instance.post('/api/user/login', {
        username,
        password,
      });
      return data;
    },
    createUser: async ({ username, password, is_admin }: CreateUserParams) => {
      const { data } = await instance.post('/api/user/create', {
        username,
        password,
        is_admin,
      });
      return data;
    },
    // User Setting
    updateSetting: async (value: SettingParams) => {
      const { data } = await instance.patch('/api/user/setting', value);
      return data;
    },
    updateSignalSetting: async (value: SignalSettingParams) => {
      const { data } = await instance.put('/api/setting/signal', value);
      return data;
    },
    updateNasSetting: async (value: NasSettingParams) => {
      const { data } = await instance.put('/api/setting/nas', value);
      return data;
    },
    updateBasSetting: async (value: BasSettingParams) => {
      const { data } = await instance.put('/api/setting/bas', value);
      return data;
    },
  },
};

export default api;
