import React, { createContext, useContext, useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import axios, { AxiosError } from 'axios';
import api, { setAuthHeader, unsetAuthHeader } from 'api/axios';
import { LoginParams, LoginResponse } from 'api/types';

import { useMutation, useQueryClient } from 'react-query';

interface AuthState {
  isLoading: boolean;
  token: string | null;
  isAuthenticated: boolean;
  isSuperUser: boolean | null;
  isAdmin: boolean | null;
  loginRequest: ({ username, password }: LoginParams) => void;
  logoutRequest: () => void;
}

const authContext = createContext<AuthState | null>(null);

export const useAuth = () => {
  const state = useContext(authContext);
  if (!state) throw new Error('Cannot find authContext Provider');
  return state;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useProvideAuth();
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

function useProvideAuth() {
  const [isLoading, setIsLoading] = useState(true);

  const [token, setToken] = useState(() => {
    if (typeof window === 'undefined') return null;

    const basic = window.localStorage.getItem('@token');
    if (!basic) return null;

    setAuthHeader(basic);

    return basic;
  });

  const [isSuperUser, setIsSuperUser] = useState(() => {
    if (typeof window === 'undefined') return null;

    const type = window.localStorage.getItem('@is_super_user');
    if (!type) return null;

    return JSON.parse(type);
  });

  const [isAdmin, setIsAdmin] = useState(() => {
    if (typeof window === 'undefined') return null;

    const type = window.localStorage.getItem('@is_admin');
    if (!type) return null;

    return JSON.parse(type);
  });

  const queryClient = useQueryClient();

  const router = useRouter();

  const loginQuery = useMutation(api.mutations.login, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data: LoginResponse) => {
      const { basic, is_superuser, is_admin } = data;

      setToken(basic);
      window.localStorage.setItem('@token', basic);

      setAuthHeader(basic);

      setIsSuperUser(is_superuser);
      window.localStorage.setItem('@is_super_user', JSON.stringify(is_superuser));

      setIsAdmin(is_admin);
      window.localStorage.setItem('@is_admin', JSON.stringify(is_admin));

      if (is_superuser) {
        router.replace('/super-user');
      } else if (is_admin) {
        router.replace('/admin');
      } else {
        router.replace('/bas');
      }
    },
    onError: (error: Error | AxiosError) => {
      try {
        if (axios.isAxiosError(error)) {
          alert('Authentication failed');
        } else {
          alert('Please try again in a few minutes');
        }
      } catch (error) {
        alert('Please check your connection');
      }
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const logoutRequest = () => {
    alert('You have been logged out.');
    setToken(null);
    window.localStorage.removeItem('@token');

    unsetAuthHeader();

    setIsSuperUser(null);
    window.localStorage.removeItem('@is_super_user');

    setIsAdmin(null);
    window.localStorage.removeItem('@is_admin');

    queryClient.clear();
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    token,
    isAuthenticated: !!token,
    isSuperUser,
    isAdmin,
    loginRequest: loginQuery.mutate,
    logoutRequest,
  };
}
